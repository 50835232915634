export const signIn = (credentials) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      var res = await firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password);
      dispatch({ type: "LOGIN_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "LOGIN_ERROR", err });
    }
  };
};

export const signInAdmin = (credentials) => {
  return async (dispatch, getState, { getFirebase, api }) => {
    const firebase = getFirebase();
    try {
      var response = await api.post('/api/companyAdmin/represent', credentials);
      if( response && response.status === 200 && response.data.data && response.data.data.token ){
        let token = response.data.data.token;
        await firebase.auth().signInWithCustomToken(token);
        dispatch({ type: "LOGIN_SUCCESS", res: {} });
        return;
      }
      dispatch({ type: "LOGIN_ERROR", err: new Error('Some error occurred!') });
    } catch (err) {
      dispatch({ type: "LOGIN_ERROR", err });
    }
  };
};

export const resetPassword = (code, newPassword) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      var res = await firebase.auth().confirmPasswordReset(code, newPassword);
      dispatch({ type: "RESET_SUCCESS", res });
    } catch (err) {
      console.log(err);
      dispatch({ type: "RESET_ERROR", err });
    }
  };
};

export const sendResetPasswordEmail = (email) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      var res = await firebase.auth().sendPasswordResetEmail(email);
      dispatch({ type: "EMAIL_SEND_SUCCESS", res });
    } catch (err) {
      // console.log(err)
      dispatch({ type: "EMAIL_SEND_ERROR", err });
    }
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore.collection("admins").doc(resp.user.uid).set({
          firstName: newUser.firstName,
          lastName: newUser.lastName,
        });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const disconnectZoomAccount = (role, profile) => {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();

    // validate account type and zoom status
    if( role !== 'TEACHER' ){
      let err = new Error('Invalid user type!');
      dispatch({ type: "ZOOM_DISCONNECT_ERROR", err });

      return Promise.reject(err);
    }

    if( !profile.isZoomAccountConnected ){
      let err = new Error('Zoom account already disconnected!');
      dispatch({ type: "ZOOM_DISCONNECT_ERROR", err });

      return Promise.reject(err);
    }

    const userUid = state.firebase.auth.uid;
    return firestore.collection('teachers').doc(userUid)
      .update({
        isZoomAccountConnected: false,
        hasLicensedZoomAccount: false,
      })
      .then(() => {
        dispatch({ type: "ZOOM_DISCONNECT_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "ZOOM_DISCONNECT_ERROR", err });
      });
  };
}