import { BrowserRouter, Route, Switch } from "react-router-dom";

import AllClasses from "./components/classes/Classes";

import Dashboard from "./components/dashboard/Dashboard";
import EditClass from "./components/classes/EditClass";

import EditSession from "./components/classes/EditSession";

import LoadingScreen from "./components/layout/LoadingScreen";
import NotFound from "./components/layout/NotFound";
import OnlyLoggedOut from "./components/auth/OnlyLoggedOut";
// import PasswordUpdate from "./components/auth/PasswordUpdate";
import PreviewClass from "./components/classes/PreviewClass";
import PrivateRoute from "./components/auth/PrivateRoute";
import Profile from "./components/profile/Profile";
import React from "react";
import ResetPassword from "./components/auth/ResetPassword";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import Faq from "./components/faq/Faq";
import Support from "./components/support/Support";
import TicketSingle from "./components/support/components/TicketSingle";

import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import EditCategory from "./components/classes/components/EditCategory";
import Students from "./components/students/Students";
import SingleStudent from "./components/students/SingleStudent";
import Payments from "./components/payments/Payments";
import ClassCards from "./components/classCards/ClassCards";
import SingleClassCard from "./components/classCards/SingleClassCard";
import AddClassCard from "./components/classCards/AddClassCard";

function App(props) {
  const { profile } = props;
  if (isLoaded(profile)) {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={
                profile.token
                  ? profile.token.claims.role === "TEACHER" ||
                    profile.token.claims.role === "COMPANY_ADMIN"
                    ? Dashboard
                    : AllClasses
                  : AllClasses
              }
            />

            <PrivateRoute path="/students" component={Students} />
            <PrivateRoute path="/student/:uid" component={SingleStudent} />

            <PrivateRoute path="/payments" component={Payments} />

            {/* <PrivateRoute exact path="/transactions" component={Transactions} />
            <PrivateRoute
              exact
              path="/transactions/:id"
              component={TxPreview}
            /> */}

            {/* <PrivateRoute
              path="/cards/preview/:classCardId/bookings/import"
              component={ClassCardBookingImport}
            />
            <PrivateRoute
              path="/cards/preview/:classCardId/bookings"
              component={ClassCardBookings}
            /> */}
            <PrivateRoute exact path="/cards" component={ClassCards} />
            <PrivateRoute exact path="/cards/add" component={AddClassCard} />

            <PrivateRoute
              exact
              path="/cards/edit/:classCardId"
              component={SingleClassCard}
            />

            {/* <PrivateRoute exact path="/posts" component={Posts} />
            <PrivateRoute
              exact
              path="/posts/:postId"
              component={SinglePostPage}
            /> */}

            <PrivateRoute exact path="/faq" component={Faq} />

            <PrivateRoute exact path="/profile" component={Profile} />
            {/* <PrivateRoute exact path="/coordinators" component={Coordinators} /> */}

            <PrivateRoute exact path="/support" component={Support} />
            <PrivateRoute exact path="/support/:id" component={TicketSingle} />

            <PrivateRoute exact path="/classes" component={AllClasses} />

            {/* <PrivateRoute
              path="/classes/preview/:classId/live-sessions/:sessionId/bookings/import"
              component={LiveSessionBookingImport}
            /> */}
            {/* <PrivateRoute
              path="/classes/preview/:classId/live-sessions/:sessionId/bookings"
              component={LiveSessionBookings}
            /> */}
            {/* <PrivateRoute
              path="/classes/edit/:classId/live-sessions/:sessionId"
              component={EditLiveSession}
            /> */}

            {/* <PrivateRoute
              path="/classes/preview/:classId/sessions/:sessionId/bookings/import"
              component={SessionBookingImport}
            /> */}
            {/* <PrivateRoute
              path="/classes/preview/:classId/sessions/:sessionId/bookings"
              component={SessionBookings}
            /> */}
            <PrivateRoute
              path="/classes/edit/:classId/sessions/:sessionId"
              component={EditSession}
            />
            <PrivateRoute path="/classes/edit/:id" component={EditClass} />

            <PrivateRoute
              path="/classes/preview/:id"
              component={PreviewClass}
            />
            <PrivateRoute
              path="/classes/:classId/categories/:id"
              component={EditCategory}
            />

            {/* <PrivateRoute path="/teachers/:uid" component={TeacherSingle} />
            <PrivateRoute path="/teachers" component={Teachers} /> */}

            <OnlyLoggedOut path="/signin" component={SignIn} />
            <OnlyLoggedOut path="/password-reset" component={ResetPassword} />
            {/* <OnlyLoggedOut path="/password-update" component={PasswordUpdate} /> */}
            <OnlyLoggedOut path="/signup" component={SignUp} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  } else {
    return <LoadingScreen />;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(App);
