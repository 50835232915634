import {
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Layout,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";

import { firestoreConnect, isLoaded } from "react-redux-firebase";

import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { editClassCard } from "../../store/actions/onlineClass";
import moment from "moment";
import { useState } from "react";

const { Paragraph, Text } = Typography;
const { Option } = Select;

const SinglePostPage = ({
  classCard,
  classCardId,
  editClassCard,
  class_card_categories,
}) => {
  const [saving, setSaving] = useState(false);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (isLoaded(classCard, class_card_categories)) {
      setCategories(classCard.categories);
    }
  }, []);

  if (isLoaded(classCard, class_card_categories)) {
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          title="Edit class card"
          className="site-page-header"
        ></PageHeader>
        {/* <ImageEditModal
            visible={imageEditVisible}
            onCreate={this.updateImage}
            loading={loading}
            onCancel={this.closeImageEditModal}
          /> */}
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title={
                <div className="class-name flex flex-row space-x-4 pr-4">
                  <Text
                    editable={{
                      onChange: (val) =>
                        editClassCard(classCardId, "name", val),
                    }}
                  >
                    {classCard.name}
                  </Text>
                  {/* <Tag color="blue">
                    Booked Students Count:{" "}
                    {classCard.total_students_booked ?? 0}
                  </Tag> */}
                </div>
              }
              style={{ margin: "1rem 1rem 0 1rem" }}
            >
              <Descriptions>
                {/* <Descriptions.Item label="Booked Students Count" span={3}>
                  <Paragraph>{classCard.total_students_booked ?? 0}</Paragraph>
                </Descriptions.Item> */}
                <Descriptions.Item label="Card Description" span={3}>
                  <Paragraph
                    editable={{
                      onChange: (val) =>
                        editClassCard(classCardId, "description", val),
                    }}
                  >
                    {classCard.description}
                  </Paragraph>
                </Descriptions.Item>

                <Descriptions.Item label="Type" span={3}>
                  <Select
                    defaultValue={classCard.type}
                    disabled={saving}
                    style={{ width: 120 }}
                    onChange={(val) => editClassCard(classCardId, "type", val)}
                  >
                    <Select.Option value="FULL">FULL</Select.Option>
                  </Select>
                </Descriptions.Item>

                <Descriptions.Item label="Is Available To Purchase" span={3}>
                  <Select
                    defaultValue={classCard.isAvailableToPurchase}
                    disabled={saving}
                    style={{ width: 120 }}
                    onChange={(val) =>
                      editClassCard(classCardId, "isAvailableToPurchase", val)
                    }
                  >
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Check Valid Date" span={3}>
                  <Select
                    defaultValue={classCard.checkValid}
                    disabled={saving}
                    style={{ width: 120 }}
                    onChange={(val) =>
                      editClassCard(classCardId, "checkValid", val)
                    }
                  >
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Valid From" span={3}>
                  <Form layout="inline">
                    <Form.Item
                      name="validFrom"
                      initialValue={moment(
                        typeof String() === typeof classCard.validFrom
                          ? classCard.validFrom
                          : classCard.validFrom.toDate()
                      )}
                    >
                      <DatePicker
                        disabled={saving}
                        allowClear={false}
                        value={moment(
                          typeof String() === typeof classCard.validFrom
                            ? classCard.validFrom
                            : classCard.validFrom.toDate()
                        )}
                        onChange={(val) =>
                          editClassCard(
                            classCardId,
                            "validFrom",
                            val.format("YYYY MM DD")
                          )
                        }
                      />
                    </Form.Item>
                  </Form>
                </Descriptions.Item>
                <Descriptions.Item label="Valid till" span={3}>
                  <Form layout="inline">
                    <Form.Item
                      name="validTill"
                      initialValue={moment(
                        typeof String() === typeof classCard.validTill
                          ? classCard.validTill
                          : classCard.validTill.toDate()
                      )}
                    >
                      <DatePicker
                        disabled={saving}
                        allowClear={false}
                        value={moment(
                          typeof String() === typeof classCard.validTill
                            ? classCard.validTill
                            : classCard.validTill.toDate()
                        )}
                        onChange={(val) =>
                          editClassCard(
                            classCardId,
                            "validTill",
                            val.format("YYYY MM DD")
                          )
                        }
                      />
                    </Form.Item>
                  </Form>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Categories">
                  <Select
                    disabled={saving}
                    style={{ width: 300 }}
                    placeholder={"Select Categories"}
                    mode="multiple"
                    allowClear={false}
                    value={categories}
                    onChange={(val) => {
                      editClassCard(classCardId, "categories", val);
                    }}
                  >
                    {class_card_categories.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Month" span={3}>
                  <Paragraph
                    editable={{
                      onChange: (val) =>
                        editClassCard(classCardId, "month", val),
                    }}
                  >
                    {classCard.month}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label="Institute" span={3}>
                  <Paragraph
                    editable={{
                      onChange: (val) =>
                        editClassCard(classCardId, "institute", val),
                    }}
                  >
                    {classCard.institute}
                  </Paragraph>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          title="Edit class card"
          className="site-page-header"
        ></PageHeader>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Class card is loading..."
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
};

const mapStateToProps = (state, routeProps) => {
  return {
    classCard: state.firestore.data.selected_class_card,
    classCardId: routeProps.match.params.classCardId,
    role: state.firebase.profile.token.claims.role,
    teacherUid: routeProps.userTeacherUid,
    class_card_categories: state.firestore.ordered.class_card_categories,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  return {
    editClassCard: (classCardId, key, value) =>
      dispatch(editClassCard(classCardId, key, value)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => {
    let out = [
      {
        collection: "class_cards",
        doc: routeProps.match.params.classCardId,
        storeAs: "selected_class_card",
      },
    ];

    out.push({
      collection: "class_card_categories",
    });

    return out;
  })
)(SinglePostPage);
