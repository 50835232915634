import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  Layout,
  PageHeader,
  Row,
  Col,
  Card,
  Statistic,
  Skeleton,
  Tag,
  Space,
} from "antd";

import { UserOutlined } from "@ant-design/icons";

const { Content, Footer } = Layout;

class Dashboard extends Component {
  render() {
    const { myProfile, statictics } = this.props;

    if (isLoaded(myProfile, statictics)) {
      return (
        <Layout className="site-layout">
          <PageHeader ghost={false} title="Admin Dashboard"></PageHeader>
          <Content style={{ margin: "1rem" }}>
            <Row gutter={16}>
              <Col span={12}>
                <Card hoverable>
                  <Statistic
                    title="Welcome Back"
                    value={myProfile.name}
                    precision={0}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card hoverable>
                  <Statistic
                    title="Total Students"
                    value={statictics["students"]["total"]}
                    valueStyle={{ color: "#8884d8" }}
                    prefix={<UserOutlined />}
                  />
                </Card>
              </Col>
            </Row>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title={
              <Skeleton.Input
                style={{ width: "300px", height: "1rem", marginTop: ".5rem" }}
                active={true}
              />
            }
            className="site-page-header"
          ></PageHeader>
          <Content style={{ margin: "1rem" }}>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Card>
                  <Skeleton paragraph={{ rows: 6 }} active />
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    myProfile: state.firestore.data.myProfile,
    zoomMeetings: state.firestore.ordered.zoomMeetings,
    statictics: state.firestore.data.statictics,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    let ret = [
      {
        collection: props.role === "TEACHER" ? "teachers" : "users",
        doc: props.auth.uid,
        storeAs: "myProfile",
      },
      {
        collection: "statictics",
      },
    ];

    return ret;
  })
)(Dashboard);
