import {
    CreditCardOutlined,
    DollarOutlined,
    BookOutlined,
    FireOutlined,
    PieChartOutlined,
    UserOutlined,
    UserSwitchOutlined,
  } from "@ant-design/icons";
  import { NavLink, withRouter } from "react-router-dom";
  
  import { Menu } from "antd";
  import React from "react";
  
  const TeacherMenuLinks = (props) => {
    const path =
      props.location.pathname !== undefined
        ? props.location.pathname
        : window.location.pathname;
    return (
      <Menu theme="dark" defaultSelectedKeys={[path]} mode="inline">
        <Menu.Item key="/" icon={<PieChartOutlined />}>
          <NavLink to="/" className="waves-effect sidenav-close">
            Home
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/classes" icon={<FireOutlined />}>
          <NavLink to="/classes" className="waves-effect sidenav-close">
            Classes
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/cards" icon={<CreditCardOutlined />}>
          <NavLink to="/cards" className="waves-effect sidenav-close">
            Class Cards
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/transactions" icon={<DollarOutlined />}>
          <NavLink to="/transactions" className="waves-effect sidenav-close">
            Transactions
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/posts" icon={<BookOutlined />}>
          <NavLink to="/posts" className="waves-effect sidenav-close">
            Posts
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/coordinators" icon={<UserSwitchOutlined />}>
          <NavLink to="/coordinators" className="waves-effect sidenav-close">
            Coordinators
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/profile" icon={<UserOutlined />}>
          <NavLink to="/profile" className="waves-effect sidenav-close">
            Profile
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  };
  
  export default withRouter(TeacherMenuLinks);
  