import { message } from "antd";

const initState = {
  authError: null,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      // console.log("LOGIN_SUCCESS");
      return {
        ...state,
        authError: null,
      };
    case "LOGIN_ERROR":
      // console.log("LOGIN_ERROR", action.err);
      message.error(action.err.message);
      return {
        ...state,
        authError: "Login Failed",
      };
    case "SIGNOUT_SUCCESS":
      // console.log("SIGNOUT_SUCCESS");
      return state;
    case "SIGNUP_SUCCESS":
      // console.log("SIGNUP_SUCCESS");
      return {
        ...state,
        authError: null,
      };
    case "SIGNUP_ERROR":
      // console.log("SIGNUP_ERROR");
      return {
        ...state,
        authError: action.err.message,
      };
    case "RESET_SUCCESS":
      // console.log("RESET_SUCCESS");
      return {
        ...state,
        authError: null,
      };
    case "RESET_ERROR":
      // console.log("RESET_ERROR");
      return {
        ...state,
        authError: action.err.message,
      };
    case "EMAIL_SEND_SUCCESS":
      // console.log("EMAIL_SEND_SUCCESS");
      return {
        ...state,
        authError: null,
      };
    case "EMAIL_SEND_ERROR":
      // console.log("EMAIL_SEND_ERROR");
      return {
        ...state,
        authError: action.err.message,
      };
    case "ZOOM_DISCONNECT_SUCCESS":
      // console.log("ZOOM_DISCONNECT_SUCCESS");
      return {
        ...state,
        authError: null,
      };
    case "ZOOM_DISCONNECT_ERROR":
      // console.log("ZOOM_DISCONNECT_ERROR");
      return {
        ...state,
        authError: action.err.message,
      };

    default:
      return state;
  }
};

export default auth;
