export const approvePayment = (docId, value) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    try {
      const res = await firestore
        .collection("payments")
        .doc(docId)
        .update({
          status: value ? "APPROVED" : "PENDING",
        });

      dispatch({ type: "PAYMENT_UPDATE_SUCCESS", res: "" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "PAYMENT_UPDATE_ERROR", err });
    }
  };
};
export const rejectPayment = (docId) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    try {
      const res = await firestore.collection("payments").doc(docId).delete();

      dispatch({ type: "PAYMENT_UPDATE_SUCCESS", res: res });
    } catch (err) {
      console.log(err);
      dispatch({ type: "PAYMENT_UPDATE_ERROR", err });
    }
  };
};
