import axios from "axios";

export const api = axios.create({
    baseURL: process.env.REACT_APP_CLOUDFUNCTIONS_URL,
    withCredentials: false,
  });
  
export const aws = axios.create({
    baseURL: process.env.REACT_APP_AWS_LAMBDA_BASE_URL,
    withCredentials: false,
});