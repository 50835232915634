import { message } from "antd";

const initState = {
  error: null,
};

const payments = (state = initState, action) => {
  switch (action.type) {
    case "PAYMENT_UPDATE_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "PAYMENT_UPDATE_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        error: action.err,
      };
    default:
      return state;
  }
};

export default payments;
