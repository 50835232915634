import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Layout,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { Component } from "react";
import {
  delAttachment,
  editSession,
  editSessionCover,
  publishSession,
  unpublishSession,
} from "../../store/actions/onlineClass";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import ImageEditModal from "../common/CoverImageEdit";

import ReactPlayer from "react-player";

import axios from "axios";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";

const { Paragraph, Text, Link } = Typography;

class EditSession extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    publishing: false,
    videoPreviewLoading: false,
    showPreview: false,
    previewUrl: "",
    previewKey: "",
    month: null,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  onFinish = async (val, field) => {
    //console.log(field, val);
    this.setState({
      loading: true,
    });
    if (this.props.session[field] !== val) {
      let data = {
        classUid: this.props.classId,
        sessionUid: this.props.sessionId,
      };
      data[field] = val;
      await this.props.editSession(data);

      if (!this.props.error) {
        message.success("Session updated successfully");
      } else {
        message.error("There was an error updating session");
      }
    }
    this.setState({
      visible: false,
      loading: false,
    });
  };

  deleteAttachment = async (file) => {
    this.setState({
      uploading: true,
    });
    if (!file.url) {
      message.warning("Something isn't right, please reload the page.");
      return;
    }
    await this.props.delAttachment({
      classUid: this.props.classId,
      sessionUid: this.props.sessionId,
      attachmentUid: file.uid,
    });
    message.success("Attachment deleted successfully");
    this.setState({
      uploading: false,
    });
  };

  publishSession = async () => {
    this.setState({
      publishing: true,
    });

    await this.props.publishSession(this.props.classId, this.props.sessionId);

    if (this.props.publishError) {
      message.error("Error when publishing the session");
    } else {
      message.success("Successfully published the session");
    }

    this.setState({
      publishing: false,
    });
  };
  unpublishSession = async () => {
    this.setState({
      publishing: true,
    });

    await this.props.unpublishSession(this.props.classId, this.props.sessionId);

    message.success("Successfully Unpublished the session");

    this.setState({
      publishing: false,
    });
  };

  initiatePreview = async () => {
    var res = await axios.post(
      process.env.REACT_APP_AWS_LAMBDA_BASE_URL +
        "/services/getEntityPreviewData",
      {
        entityType: "SESSION",
        entityUid: `${this.props.classId}_${this.props.sessionId}`,
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
    );
    if (res.status === 200 && res.data?.success) {
      this.setState({
        showPreview: true,
        previewUrl: res.data?.data?.videoUrl,
        previewKey: res.data?.data?.key,
      });
    } else {
      message.error("Error retrieving media");
    }
  };

  openImageEditModal = () => {
    this.setState({
      ImageEditVisible: true,
    });
  };
  closeImageEditModal = () => {
    this.setState({
      ImageEditVisible: false,
    });
  };

  updateImage = async (newImageFile) => {
    this.setState({
      loading: true,
    });
    await this.props.editSessionCover({
      classUid: this.props.classId,
      sessionUid: this.props.sessionId,
      file: newImageFile,
    });

    this.setState({
      loading: false,
    });
    this.closeImageEditModal();
  };

  onChangeMonth = (val) => {
    this.setState({
      month: val,
    });
  };

  getClassCard = () => {
    const classCards = this.props.class_cards;
    if (!classCards) {
      return [];
    }

    if (!this.state.month) {
      return classCards;
    }

    let firstDayOfMonth = this.state.month.clone().startOf("month");
    let endOfMonth = this.state.month.clone().endOf("month");

    var filtered = classCards.filter((classCard) => {
      var validFrom = moment(classCard.validFrom?.toDate());
      var validTill = moment(classCard.validTill?.toDate());

      return (
        validFrom.isSameOrAfter(firstDayOfMonth) &&
        validTill.isSameOrBefore(endOfMonth)
      );
    });

    return filtered;
  };

  // getContentId = () => {
  //   // <base64("<VIDEOTYPE>_<CLASS_ID>_<SESSION_ID>")>
  //   const contentId = `SESSION_${this.props.classId}_${this.props.sessionId}`;

  //   if (window.btoa) {
  //     return btoa(contentId);
  //   }

  //   return "";
  // };

  onClickPreviewVideo = async () => {
    this.setState({
      videoPreviewLoading: true,
    });

    await this.initiatePreview();

    this.setState({
      videoPreviewLoading: false,
    });
  };

  render() {
    let fileList = [];
    const {
      session,
      classId,
      sessionId,
      attachments,

      categories,
    } = this.props;

    if (session != null && isLoaded(session, attachments, categories)) {
      for (let attachment of attachments) {
        fileList.push({
          uid: attachment.id,
          name: attachment.name,
          url: attachment.url,
          status: "done",
        });
      }

      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit session"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <ImageEditModal
            aspect={16 / 9}
            visible={this.state.ImageEditVisible}
            onCreate={this.updateImage}
            loading={this.state.loading}
            onCancel={this.closeImageEditModal}
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(newStr, "name"),
                            }
                      }
                    >
                      {session.name}
                    </Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Space>
                    <Avatar shape="square" src={session.coverUrl} />
                    <EditOutlined onClick={this.openImageEditModal} />
                  </Space>
                }
              >
                {session.type === "VIMEO" ? (
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Typography.Title level={4}>
                        Session Details
                      </Typography.Title>
                      <div style={{ margin: "20px 0px" }} className="">
                        <ReactPlayer controls url={session.videoUrl} />
                      </div>
                    </Col>
                  </Row>
                ) : null}
                {session.type === "YOUTUBE" ? (
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Typography.Title level={4}>
                        Session Details
                      </Typography.Title>
                      <div style={{ margin: "20px 0px" }} className="">
                        <ReactPlayer controls url={session.videoUrl} />
                      </div>
                    </Col>
                  </Row>
                ) : null}

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Descriptions>
                      <Descriptions.Item label="Session Type" span={3}>
                        <Select
                          defaultValue={session.type ? session.type : "VIMEO"}
                          disabled={this.state.loading}
                          style={{ width: 120 }}
                          onChange={(val) => this.onFinish(val, "type")}
                        >
                          <Select.Option value="VIMEO">VIMEO</Select.Option>
                          <Select.Option value="YOUTUBE">YOUTUBE</Select.Option>
                        </Select>
                      </Descriptions.Item>

                      <Descriptions.Item label={"Session Video Url"} span={3}>
                        <Text
                          copyable
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "videoUrl"),
                                }
                          }
                        >
                          {session.videoUrl}
                        </Text>
                      </Descriptions.Item>

                      <Descriptions.Item label="Sub Title" span={3}>
                        <Text
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "subHeading"),
                                }
                          }
                        >
                          {session.subHeading}
                        </Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Class Cards" span={3}>
                        <Form layout="inline">
                          <Form.Item style={{ width: "300px" }}>
                            <DatePicker
                              onChange={this.onChangeMonth}
                              picker="month"
                            />
                          </Form.Item>
                          <Form.Item
                            name="class_cards"
                            className="mt-3"
                            style={{ width: "300px" }}
                            initialValue={session.classCards}
                          >
                            <Select
                              showSearch
                              placeholder="Select Class Cards"
                              mode="multiple"
                              allowClear
                              onChange={(val) => {
                                this.onFinish(val, "classCards");
                              }}
                              disabled={this.state.loading}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.getClassCard().map((card) => {
                                return (
                                  <Select.Option value={card.id} key={card.id}>
                                    <div>
                                      {card.name} LKR{card.amount}
                                    </div>
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>

                      <Descriptions.Item label="Session Categories" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="categories"
                            style={{ width: "300px" }}
                            initialValue={session.categories}
                          >
                            <Select
                              showSearch
                              placeholder="Select Categories"
                              mode="multiple"
                              allowClear
                              onChange={(val) => {
                                this.onFinish(val, "categories");
                              }}
                              disabled={this.state.loading}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {categories.map((cat) => {
                                return (
                                  <Select.Option value={cat.id} key={cat.id}>
                                    {cat.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>
                      <Descriptions.Item label="Video Start" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="vidStart"
                            initialValue={moment(session.vidStart.toDate())}
                          >
                            <DatePicker
                              disabled={this.state.loading}
                              showTime
                              allowClear={false}
                              value={moment(session.vidStart.toDate())}
                              onChange={(val) =>
                                this.onFinish(val.toDate(), "vidStart")
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>

                      <Descriptions.Item label="Description" span={3}>
                        <Paragraph
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "description"),
                                }
                          }
                        >
                          {session.description}
                        </Paragraph>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={12}>
                    <Descriptions>
                      <Descriptions.Item label={"Publish Session:"} span={3}>
                        {session.isPublished ? (
                          <Button
                            loading={this.state.publishing}
                            onClick={() =>
                              this.unpublishSession(classId, sessionId)
                            }
                            type={"danger"}
                          >
                            Unpublish
                          </Button>
                        ) : (
                          <Button
                            loading={this.state.publishing}
                            onClick={() =>
                              this.publishSession(classId, sessionId)
                            }
                            type={"primary"}
                          >
                            Publish
                          </Button>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={"Attachments"}
                style={{ margin: "1rem 1rem 1rem 1rem" }}
              >
                <Form>
                  <Form.Item className="attachment-file-list">
                    <Upload.Dragger
                      accept=".pdf"
                      listType="picture"
                      defaultFileList={fileList}
                      onRemove={this.deleteAttachment}
                      disabled={this.state.uploading}
                      action={
                        process.env.REACT_APP_CLOUDFUNCTIONS_URL +
                        "/api/sessions/uploadAttachment/" +
                        classId +
                        "/" +
                        sessionId
                      }
                      headers={{
                        Authorization: `Bearer ${this.props.token}`,
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Only .pdf files are allowed to upload, make sure the
                        filename is correct before uploading
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
    //  else if (isEmpty(session)) {
    //   return (
    //     <Layout>
    //       <PageHeader
    //         onBack={() => window.history.back()}
    //         ghost={false}
    //         title="Edit session"
    //         className="site-page-header"
    //       ></PageHeader>
    //       <Row>
    //         <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
    //           <Card
    //             title="Session doesn't exists"
    //             style={{ margin: "1rem 1rem 0 1rem" }}
    //           >
    //             <Result
    //               status="404"
    //               title="404"
    //               subTitle="Sorry, the session you've selected does not exist."
    //             />
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Layout>
    //   );
    // }
    else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit session"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Session is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    attachments: state.firestore.ordered.selected_session_attachments,
    categories: state.firestore.ordered.selected_class_categories,
    class_cards: state.firestore.ordered.class_cards,
    session: state.firestore.ordered.selected_session
      ? state.firestore.ordered.selected_session[0]
      : null,
    classId: routeProps.match.params.classId,
    sessionId: routeProps.match.params.sessionId,
    sessionVideoUrl: state.onlineClass.sessionVideoUrl,
    token: state.firebase.profile.token.token,
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
    publishError: state.onlineClass.error,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  return {
    editSessionCover: (data) => dispatch(editSessionCover(data)),
    editSession: (data) => dispatch(editSession(data)),
    delAttachment: (data) => dispatch(delAttachment(data)),
    publishSession: (classUid, sessionUid) =>
      dispatch(publishSession(classUid, sessionUid)),
    unpublishSession: (classUid, sessionUid) =>
      dispatch(unpublishSession(classUid, sessionUid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => {
    let query = [
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          {
            collection: "categories",
            orderBy: "name",
          },
        ],
        storeAs: "selected_class_categories",
      },
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          { collection: "sessions", doc: routeProps.match.params.sessionId },
        ],
        storeAs: "selected_session",
      },
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          {
            collection: "sessions",
            doc: routeProps.match.params.sessionId,
            subcollections: [{ collection: "attachments", orderBy: ["name"] }],
          },
        ],
        storeAs: "selected_session_attachments",
      },
    ];

    if (routeProps.session) {
      query.push({
        collection: "class_cards",
      });
    }

    return query;
  })
)(EditSession);
