import {
  CreditCardOutlined,
  DollarOutlined,
  FireOutlined,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import React from "react";

const UserMenuLinks = (props) => {
  return (
    <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
      <Menu.Item key="/" icon={<PieChartOutlined />}>
        <NavLink to="/" className="waves-effect sidenav-close">
          Home
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/classes" icon={<FireOutlined />}>
        <NavLink to="/classes" className="waves-effect sidenav-close">
          Classes
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/cards" icon={<CreditCardOutlined />}>
        <NavLink to="/cards" className="waves-effect sidenav-close">
          Class Cards
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/transactions" icon={<DollarOutlined />}>
        <NavLink to="/transactions" className="waves-effect sidenav-close">
          Transactions
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/profile" icon={<UserOutlined />}>
        <NavLink to="/profile" className="waves-effect sidenav-close">
          Profile
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default UserMenuLinks;
