import {
  Button,
  Card,
  Col,
  Layout,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import ClassCardsTable from "./components/ClassCardsTable";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import CardCategoriesList from "./components/CardCategoriesList";

const { Text } = Typography;
const { Option } = Select;

const months = {
  0: "January",
  1: "Feburary",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

class ClassCards extends Component {
  state = {
    loading: false,
    filterAllYears: [],
    filterTeacher: null,
    filterCategoriesTeacher: null,
    filterYear: null,
    filterMonth: null,
    filteredClassCards: null,
    filteredClassCardCategories: null,
    selectedCategoryId: null,
    selectedCategoryName: null,
  };

  componentDidUpdate() {
    if (isLoaded(this.props.class_cards)) {
      if (this.state.filteredClassCards == null) {
        // calculate valid from to dates
        var validFromDates = this.props.class_cards.map((classCard) => {
          return moment(classCard.validFrom?.toDate());
        });

        var validTillDates = this.props.class_cards.map((classCard) => {
          return moment(classCard.validTill?.toDate());
        });

        var allDates = [...validFromDates, ...validTillDates];
        var allYears = allDates.map((date) => date.year());
        var uniqueYears = [...new Set(allYears)];

        this.setState({
          filteredClassCards: this.props.class_cards,
          filterAllYears: uniqueYears,
        });
      }
    }
  }

  selectCategory = (id, name) => {
    this.setState(
      {
        selectedCategoryId: id,
        selectedCategoryName: name,
      },
      () => {
        this.filterClassCards();
      }
    );
  };

  onChangeFilterTeacherInCategories(value) {
    this.setState(
      {
        filterCategoriesTeacher: value ? value : null,
      },
      () => {
        this.filterClassCardsCategories();
      }
    );
  }

  onChangeFilterTeacher(value) {
    this.setState(
      {
        filterTeacher: value ? value : null,
        filterYear: value ? this.state.filterYear : null,
        filterMonth: value ? this.state.filterMonth : null,
      },
      () => {
        this.filterClassCards();
      }
    );
  }

  onChangeFilterYear(value) {
    this.setState(
      {
        filterYear: value ? value : null,
        filterMonth: value ? this.state.filterMonth : null,
      },
      () => {
        this.filterClassCards();
      }
    );
  }

  onChangeFilterMonth(value) {
    this.setState(
      {
        filterMonth: value ? value : null,
      },
      () => {
        this.filterClassCards();
      }
    );
  }

  filterClassCardsCategories() {
    let teacher = this.state.filterCategoriesTeacher;

    // filter by teacher
    let filteredByTeacher = teacher
      ? this.props.class_card_categories.filter((classCard) => {
          return classCard.teacherUid === teacher;
        })
      : this.props.class_card_categories;

    this.setState({
      filteredClassCardCategories: filteredByTeacher,
    });
  }

  filterClassCards() {
    let teacher = this.state.filterTeacher;
    let year = this.state.filterYear;
    let month = this.state.filterMonth;

    let selectedCategory = this.state.selectedCategoryId;

    if (month) {
      month = parseInt(month);
    }

    // filter by teacher
    let filteredByTeacher = teacher
      ? this.props.class_cards.filter((classCard) => {
          return classCard.teacherUid === teacher;
        })
      : this.props.class_cards;

    if (selectedCategory) {
      filteredByTeacher = filteredByTeacher.filter((classCard) => {
        if (classCard.categories) {
          return classCard.categories.includes(selectedCategory);
        }
        return false;
      });
    }

    // filter by year and month
    if (!year || !month) {
      this.setState({
        filteredClassCards: filteredByTeacher,
      });
      return;
    }

    let nextYear = month >= 11 ? year + 1 : year;
    let nextMonth = month >= 11 ? 0 : month + 1;

    let firstDayOfMonth = this.getStartOfMonth(year, month);
    let firstDayOfNextMonth = this.getStartOfMonth(nextYear, nextMonth);

    var filtered = filteredByTeacher.filter((classCard) => {
      var validFrom = moment(classCard.validFrom?.toDate());
      var validTill = moment(classCard.validTill?.toDate());

      return (
        validFrom.isSameOrAfter(firstDayOfMonth) &&
        validTill.isSameOrBefore(firstDayOfNextMonth)
      );
    });

    this.setState({
      filteredClassCards: filtered,
    });
  }

  getStartOfMonth(year, month) {
    var newDate = moment();
    newDate.set("year", year);
    newDate.set("month", month);
    newDate.set("date", 1);
    newDate.startOf("day");

    return newDate;
  }

  render() {
    const { class_cards, class_card_categories, role, teacherUid } = this.props;
    // const { imageUrl } = this.state;
    if (isLoaded(class_cards)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Class cards"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <CardCategoriesList
            currrentTeacher={teacherUid}
            filterTeacher={this.state.filterCategoriesTeacher}
            onChangeFilterTeacher={(value) => {
              this.onChangeFilterTeacherInCategories(value);
            }}
            preview={false}
            role={role}
            onCategorySelect={this.selectCategory}
            selectedCategoryId={this.state.selectedCategoryId}
            addClassCardCategory={this.state.selectedCategoryName}
            data={
              this.state.filteredClassCardCategories ?? class_card_categories
            }
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Class cards"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={[
                  <Space>
                    <Select
                      placeholder="Year"
                      value={this.state.filterYear}
                      onChange={this.onChangeFilterYear.bind(this)}
                      style={{ width: 120 }}
                      allowClear
                    >
                      {this.state.filterAllYears.map((year) => (
                        <Option value={year} key={year}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                    ,
                    <Select
                      placeholder="Month"
                      value={
                        this.state.filterMonth
                          ? months[this.state.filterMonth]
                          : null
                      }
                      onChange={this.onChangeFilterMonth.bind(this)}
                      disabled={this.state.filterYear == null}
                      style={{ width: 120 }}
                      allowClear
                    >
                      {Object.keys(months).map((index) => (
                        <Option value={index} key={index}>
                          {months[index]}
                        </Option>
                      ))}
                    </Select>
                    ,
                    <Button type="primary">
                      <Link to="/cards/add">Add Class Card</Link>
                    </Button>
                  </Space>,
                ]}
              >
                <ClassCardsTable
                  selectCategory={{
                    name: this.state.selectedCategoryName,
                    id: this.state.selectedCategoryId,
                  }}
                  class_cards={this.state.filteredClassCards}
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          title="Class cards"
          className="site-page-header"
        ></PageHeader>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Please wait a while..."
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    uid: state.firebase.auth.uid,
    addError: state.onlineClass.addError,
    class_cards: state.firestore.ordered.class_cards,
    class_card_categories: state.firestore.ordered.class_card_categories,
    teacherUid: routeProps.userTeacherUid,
    role: routeProps.userRole,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    let queries = [];

    if (props.role === "COMPANY_ADMIN") {
      queries.push(
        {
          collection: "class_cards",
          orderBy: ["createdAt", "desc"],
        },
        {
          collection: "class_card_categories",
        }
      );
    } else {
      queries.push(
        {
          collection: "class_cards",
          where: [["teacherUid", "==", props.teacherUid]],
        },
        {
          collection: "class_card_categories",
          where: [["teacherUid", "==", props.teacherUid]],
        }
      );
    }

    return queries;
  })
)(ClassCards);
