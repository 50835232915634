const initState = {
  error: null,
};

const onlineClass = (state = initState, action) => {
  //console.log(action.type, action.err);
  switch (action.type) {
    case "ADD_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "ADD_ERROR":
      return {
        ...state,
        error: action.err,
      };
    case "EDIT_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "EDIT_ERROR":
      return {
        ...state,
        error: action.err,
      };
    case "DELETE_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "DELETE_ERROR":
      return {
        ...state,
        error: action.err,
      };
    case "PUBLISH_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "PUBLISH_ERROR":
      return {
        ...state,
        error: action.err,
      };

    default:
      return state;
  }
};

export default onlineClass;
