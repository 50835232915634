import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Switch,
  Upload,
  message,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import React, { Component } from "react";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import ImgCrop from "antd-img-crop";
import Session from "./Session";
import { addSession } from "./../../../store/actions/onlineClass";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";

class Sessions extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    file: null,
    imageUid: "",
    videoType: "DEFAULT",
    month: null,
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
        imageUid: "",
      });
  };
  onFinish = async (val) => {
    if (!this.state.imageUid) {
      message.error("Select a cover image");
      return;
    }
    this.setState({
      loading: true,
    });

    let data = {
      name: val.name,
      type: val.type,
      fee: val.fee,
      vidStart: val.start.format(),
      duration: val.duration,
      videoUrl: val.videoUrl ? val.videoUrl : null,

      coverUid: this.state.imageUid,
      description: val.description,
      subHeading: val.subHeading,

      categories: val.categories ? val.categories : [],
      classCards: val.classCards ? val.classCards : [],
    };
    await this.props.addSession(data);
    if (!this.props.error) {
      message.success("Session added successfully");
    } else {
      message.error("There was an error adding session");
    }
    this.setState({
      visible: false,
      loading: false,
      imageUid: "",
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleCoverChange = (val) => {
    //console.log(val);
    this.setState({ imageUid: "" });
    if ("response" in val.file) {
      if (val.file.response.success) {
        this.setState({
          imageUid: val.file.response.data ? val.file.response.data[0].uid : "",
          uploading: false,
        });
        message.success("Cover uploaded successfully");
      } else {
        message.warn("Cover upload failed, please try again!");
      }
    }
    if (val.file.status === "uploading") {
      this.setState({ uploading: true });
    }
    this.setState({ coverFile: val.file });
  };

  videoTypeChange = (val) => {
    this.setState({
      videoType: val,
    });
  };

  onChangeMonth = (val) => {
    this.setState({
      month: val,
    });
  };

  getClassCard = () => {
    const classCards = this.props.class_cards;
    if (!classCards) {
      return [];
    }

    if (!this.state.month) {
      return classCards;
    }

    let firstDayOfMonth = this.state.month.clone().startOf("month");
    let endOfMonth = this.state.month.clone().endOf("month");

    var filtered = classCards.filter((classCard) => {
      var validFrom = moment(classCard.validFrom?.toDate());
      var validTill = moment(classCard.validTill?.toDate());

      return (
        validFrom.isSameOrAfter(firstDayOfMonth) &&
        validTill.isSameOrBefore(endOfMonth)
      );
    });

    return filtered;
  };

  render() {
    const { sessions, categories, selectedCategory } = this.props;
    const { imageUid } = this.state;
    const modal = (
      <>
        <Modal
          centered
          title={"Add new session"}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          okButtonProps={{ loading: this.state.loading }}
          cancelButtonProps={{ disabled: this.state.loading }}
          width={720}
        >
          <Form
            onFinish={this.onFinish}
            validateMessages={{ required: "${label} is required!" }}
            ref={this.formRef}
            layout="vertical"
          >
            <Form.Item
              name={"cover"}
              label="Cover Image"
              className={"question-image-list"}
            >
              <ImgCrop rotate aspect={16 / 9}>
                <Upload
                  accept={".jpg, .png"}
                  listType="picture-card"
                  className="class-cover-uploader"
                  onPreview={this.handlePreview}
                  disabled={this.state.loading || this.state.uploading}
                  onChange={this.handleCoverChange}
                  showUploadList={false}
                  action={
                    process.env.REACT_APP_CLOUDFUNCTIONS_URL +
                    "/api/sessions/uploadCover"
                  }
                  headers={{
                    Authorization: `Bearer ${this.props.profile.token.token}`,
                  }}
                >
                  {imageUid ? (
                    <CheckCircleOutlined />
                  ) : (
                    <div>
                      {this.state.uploading ? (
                        <LoadingOutlined />
                      ) : (
                        <PlusOutlined />
                      )}
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>
            <Form.Item
              name={"name"}
              label="Session Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"subHeading"}
              label="Session Subtitle"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"description"}
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item name={"type"} label="Video Type" initialValue={"VIMEO"}>
              <Select
                disabled={this.state.loading}
                style={{ width: 120 }}
                onChange={this.videoTypeChange}
              >
                <Select.Option value="VIMEO">VIMEO</Select.Option>
                <Select.Option value="YOUTUBE">YOUTUBE</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name={"videoUrl"} label="Video URL">
              <Input />
            </Form.Item>

            <Form.Item
              label="Session Start Time"
              name="start"
              rules={[{ required: true }]}
            >
              <DatePicker disabled={this.state.loading} showTime />
            </Form.Item>

            <Form.Item name="categories" label="Categories">
              <Select
                showSearch
                mode="multiple"
                allowClear
                placeholder="Select Categories"
                disabled={this.state.loading}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {categories &&
                  categories.map((cat) => {
                    return (
                      <Select.Option value={cat.id} key={cat.id}>
                        {cat.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={() =>
            this.setState({
              previewVisible: false,
            })
          }
        >
          <img
            alt="selectedple"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </>
    );
    if (!isLoaded(sessions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Sessions"
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      );
    }
    if (isEmpty(sessions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Sessions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Empty
                description={<span>There are no sessions to be found</span>}
              >
                <Button type="primary" onClick={() => this.showModal()}>
                  Add new
                </Button>
              </Empty>
            </Card>
          </Col>
          {modal}
        </Row>
      );
    }

    return (
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
          <Card
            title={
              selectedCategory?.id
                ? `Sessions of category ${selectedCategory.name}`
                : "All Sessions"
            }
            style={{ margin: "1rem 1rem 0 1rem" }}
            extra={
              <Button type="primary" onClick={() => this.showModal()}>
                Add a session
              </Button>
            }
          >
            <Row gutter={[16, 16]}>
              {sessions.map((session) => {
                return (
                  <Col span={6} key={session.id}>
                    <Session session={session} selected={this.props.selected} />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
        {modal}
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  //console.log(state.firestore);
  return {
    profile: state.firebase.profile,
    sessions: state.firestore.ordered[props.selected.id + "_sessions"],
    categories: state.firestore.ordered[props.selected.id + "_categories"],
    class_cards: state.firestore.ordered.class_cards,
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addSession: (data) =>
      dispatch(addSession({ ...data, classUid: props.selected.id })),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let out = [
      {
        collection: "classes",
        doc: props.selected.id,
        subcollections: [{ collection: "categories", orderBy: "name" }],
        storeAs: props.selected.id + "_categories",
      },
    ];
    if (props.selectedCategory?.id) {
      out.push({
        collection: "classes",
        doc: props.selected.id,
        subcollections: [
          {
            collection: "sessions",
            where: ["categories", "array-contains", props.selectedCategory.id],
            orderBy: ["vidStart", "desc"],
          },
        ],
        storeAs: props.selected.id + "_sessions",
      });
    } else {
      out.push({
        collection: "classes",
        doc: props.selected.id,
        subcollections: [
          {
            collection: "sessions",
            orderBy: ["vidStart", "desc"],
          },
        ],
        storeAs: props.selected.id + "_sessions",
      });
    }
    return out;
  })
)(Sessions);
