import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn, signInAdmin } from "../../store/actions/auth";
import { NavLink } from "react-router-dom";
import { Form, Input, Button, Card, Row, Col, Typography } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

class SignIn extends Component {
  state = {
    loading: false,
  };
  handleSubmit = async (e) => {
    this.setState({
      loading: true,
    });
    await this.props.signIn(e);
  };
  render() {
    return (
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col>
          <div className="container" style={{ maxWidth: "420px" }}>
            <Card
              style={{ textAlign: "center" }}
              hoverable={true}
              cover={
                <>
                  <img
                    style={{ padding: "20px 20px 0 20px" }}
                    alt="logo"
                    src="./logo.jpg"
                  />
                  <Typography.Text strong>
                    Enter your credentials
                  </Typography.Text>
                </>
              }
            >
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={this.handleSubmit}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Email!",
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please enter your Password!" },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item> */}
                  <NavLink
                    to={{
                      pathname: "/password-reset",
                    }}
                    className="waves-effect sidenav-close"
                  >
                    Forgot password?
                  </NavLink>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.state.loading}
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    signInAdmin: (creds) => dispatch(signInAdmin(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
