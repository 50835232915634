import {
  Avatar,
  Card,
  Col,
  Layout,
  PageHeader,

  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
  Switch,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { Component, useState } from "react";
import { editClassCover, editClass } from "./../../store/actions/onlineClass";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import ImageEditModal from "../common/CoverImageEdit";
import CategoriesList from "./components/CategoriesList";
import Sessions from "./components/Sessions";

import { compose } from "redux";
import { connect } from "react-redux";

const { Paragraph, Text } = Typography;

const types = [
  { label: "Video Session", value: "session" },
  { label: "Zoom Sessions", value: "live" },
];

class EditClass extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    updating: false,
    ImageEditVisible: false,
    type: "session", // "session" and "live" are the possible values
    selectedCategoryId: null,
    selectedCategoryName: null,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  onTypeChange = (e) => {
    this.setState({
      type: e.target.value,
    });
  };
  selectCategory = (id, name) => {
    this.setState({
      selectedCategoryId: id,
      selectedCategoryName: name,
    });
  };

  openImageEditModal = () => {
    this.setState({
      ImageEditVisible: true,
    });
  };
  closeImageEditModal = () => {
    this.setState({
      ImageEditVisible: false,
    });
  };

  updateImage = async (newImageFile) => {
    console.log(newImageFile);
    this.setState({
      loading: true,
    });

    await this.props.editClassCover({ file: newImageFile });

    this.setState({
      loading: false,
    });
    this.closeImageEditModal();
  };

  render() {
    const { selected, classId, role, categories } = this.props;
    if (
      selected != null &&
      isLoaded(selected, categories) &&
      selected.id === classId
    ) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit class"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <ImageEditModal
            visible={this.state.ImageEditVisible}
            onCreate={this.updateImage}
            loading={this.state.loading}
            onCancel={this.closeImageEditModal}
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text
                      editable={{
                        onChange: (value) =>
                          this.props.editClass("name", value),
                      }}
                    >
                      {selected.name}
                    </Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <div onClick={this.openImageEditModal}>
                    <Avatar shape="square" src={selected.cover} />
                  </div>
                }
              >
                <Row>
                  <Col span={8}>
                    <div>
                      Active :{" "}
                      <Switch
                        defaultChecked={selected.isActive}
                        onChange={(checked) =>
                          this.props.editClass("isActive", checked)
                        }
                      />
                    </div>
                  </Col>
                  {/* <Col span={8}>
                    <div>
                      Live Sessions Enabled :{" "}
                      <Switch
                        defaultChecked={selected.liveSessionsEnabled}
                        onChange={(checked) =>
                          this.props.editClass("liveSessionsEnabled", checked)
                        }
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      Live Sessions Only :{" "}
                      <Switch
                        defaultChecked={selected.liveSessionsOnly}
                        onChange={(checked) =>
                          this.props.editClass("liveSessionsOnly", checked)
                        }
                      />
                    </div>
                  </Col> */}
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Space direction="vertical">
                    <div>
                      Description :{" "}
                      <Paragraph
                        editable={{
                          onChange: (value) =>
                            this.props.editClass("description", value),
                        }}
                      >
                        {selected.description}
                      </Paragraph>
                    </div>
                  </Space>
                </Row>
              </Card>

              {/* <Row>
                <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
                  <div style={{ margin: "1rem 1rem 0 1rem" }}>
                    <Radio.Group
                      options={types}
                      onChange={this.onTypeChange}
                      optionType="button"
                      buttonStyle="solid"
                      value={this.state.type}
                    />
                  </div>
                </Col>
              </Row> */}

              {this.state.type === "session" ? (
                <>
                  <CategoriesList
                    data={categories}
                    onCategorySelect={this.selectCategory}
                    selectedCategoryId={this.state.selectedCategoryId}
                    classId={classId}
                    preview={false}
                  />
                  <Sessions
                    selectedCategory={{
                      name: this.state.selectedCategoryName,
                      id: this.state.selectedCategoryId,
                    }}
                    selected={{ ...selected, id: classId }}
                  />
                </>
              ) : null}

              {/* {this.state.type === "live" ? (
                <LiveSessions selected={{ ...selected, id: classId }} />
              ) : null} */}
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit class"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Class is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    categories: state.firestore.ordered.selected_class_categories ?? [],
    selected: state.firestore.ordered.selected_class
      ? state.firestore.ordered.selected_class[0]
      : state.firestore.data.selected_class,
    classId: routeProps.match.params.id,
    role: routeProps.userRole,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  var classId = routeProps.match.params.id;
  return {
    editClassCover: (data) => dispatch(editClassCover(data, classId)),
    editClass: (field, value) => dispatch(editClass(classId, field, value)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => [
    {
      collection: "classes",
      doc: routeProps.match.params.id,
      subcollections: [{ collection: "categories" }],
      storeAs: "selected_class_categories",
    },
    {
      collection: "classes",
      doc: routeProps.match.params.id,
      storeAs: "selected_class",
    },
  ])
)(EditClass);
