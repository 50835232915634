import {
  Card,
  Table,
  Space,
  Button,
  Image,
  Tag,
  message,
  Popconfirm,
} from "antd";

import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import moment from "moment";
import { FileSearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { approvePayment, rejectPayment } from "../../../store/actions/payments";

class PaymentsTab extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    showNotVerified: false,
    approving: false,
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.setState({ searchText: "", showNotVerified: false });
    confirm();
  };

  render() {
    const { payments } = this.props;

    if (isLoaded(payments)) {
      const columns = [
        {
          title: "Image/Slip",
          dataIndex: "image",
          key: "image",
          render: (text, record) => {
            return text.split(".").pop() === "pdf" ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a target={"_blank"} href={text}>
                <Button icon={<FileSearchOutlined />} type="primary">
                  Open
                </Button>
              </a>
            ) : (
              <Image placeholder="" width={50} height={50} src={text} />
            );
          },
        },
        {
          title: "Entity Name",
          dataIndex: "entityName",
          key: "entityName",
          render: (text, record) => (
            <Link to={`/cards/edit/${record.entityUid}`}>
              <Tag color={"blue"}>
                {record.entityName} - Rs.{record.amount}
              </Tag>
            </Link>
          ),
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          render: (text, record) => {
            if (text === "ONLINE") {
              return <Tag color="orange">{text}</Tag>;
            }

            return <Tag color="magenta">{text}</Tag>;
          },
        },

        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          sorter: (a, b) => a.createdAt - b.createdAt,
          sortDirections: ["descend", "ascend"],
          render: (text, record) => (
            <span>{moment(text).format("YYYY-MM-DD HH:mm A").toString()}</span>
          ),
        },
        {
          title: "Actions",
          key: "actions",
          render: (text, record) => (
            <Space>
              <Link to={`/student/${record.student}`}>
                <Button size="small">Student</Button>
              </Link>
              <Link to={`/classes/preview/${record.class}`}>
                <Button size="small">Class</Button>
              </Link>
              {/* <Link to={`/payments/${record.ley}`}>
                <Button icon={<EyeOutlined />} size="small" type="primary">
                  View Payment
                </Button>
              </Link> */}
            </Space>
          ),
        },
        {
          title: "",
          key: "approved",
          filters: [
            {
              text: "Approved",
              value: "APPROVED",
            },
            {
              text: "Not Approved",
              value: "PENDING",
            },
          ],
          onFilter: (value, record) => record.status === value,
          render: (text, record) => (
            <Space>
              {record.status === "APPROVED" ? (
                <Button
                  danger
                  type="default"
                  onClick={async () => {
                    this.setState({
                      approving: true,
                    });
                    await this.props.approvePayment(record.key, false);
                    message.success("Payment Updated!");
                    this.setState({
                      approving: false,
                    });
                  }}
                  disabled={this.state.approving}
                  loading={this.state.approving}
                >
                  Disapprove
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={async () => {
                    this.setState({
                      approving: true,
                    });
                    await this.props.approvePayment(record.key, true);
                    message.success("Payment Updated!");
                    this.setState({
                      approving: false,
                    });
                  }}
                  disabled={this.state.approving}
                  loading={this.state.approving}
                >
                  Approve
                </Button>
              )}
              <Popconfirm
                title="Confirm to reject"
                onConfirm={async () => {
                  await this.props.rejectPayment(record.key);
                }}
              >
                <Button type="primary" danger>
                  Reject
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ];

      let data = payments.map((payment) => ({
        key: payment.id,
        student: payment.student,
        class: payment.class,
        amount: payment.amount,
        entityUid: payment.entityUid,
        entityName: payment.entityName,
        image: payment.image,
        type: payment.type,
        status: payment.status,
        createdAt: payment?.createdAt?.toDate(),
      }));

      return (
        <Card title="All Payments">
          <Table dataSource={data} columns={columns} />
        </Card>
      );
    } else {
      return (
        <Card title="All Payments" bodyStyle={{ paddingTop: 0 }}>
          <Table loading={true} className="pt-3" />
        </Card>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    payments: state.firestore.ordered.payments,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    approvePayment: (docId, value) => dispatch(approvePayment(docId, value)),
    rejectPayment: (docId) => dispatch(rejectPayment(docId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const out = [];
    const uid = props.studentUid;
    out.push({
      collection: "payments",
      orderBy: ["createdAt", "desc"],
      where: [["student", "==", uid]],
    });

    return out;
  })
)(PaymentsTab);
