export const addCategory = (data) => {
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState();

      var formData = new FormData();
      formData.append("image", data.file);
      var res = await api.post("/api/categories/uploadCover", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      if (res.status === 200) {
        await api.post(
          "/api/categories",
          {
            coverUid: res.data?.data[0].uid,
            description: data.description,
            name: data.name,
            classUid: data.classId,
          },
          {
            headers: {
              Authorization: "Bearer " + state.firebase.profile.token.token,
            },
          }
        );
      }
      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const editCategory = (classId, categoryId, field, value) => {
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState();
      // update body
      let updateData = {
        classUid: classId,
        categoryUid: categoryId,
      };
      updateData[field] = value;

      var res = await api.patch(`/api/categories`, updateData, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const editCategoryCover = (data, classId, categoryId) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var formData = new FormData();
      formData.append("image", data.file);
      var res = await api.post("/api/categories/uploadCover", formData, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200 && res.data?.data) {
        await api.patch(
          "/api/categories/",
          {
            classUid: classId,
            categoryUid: categoryId,
            coverUid: res.data.data[0].uid,
          },
          {
            headers: {
              Authorization: "Bearer " + state.firebase.profile.token.token,
            },
          }
        );
      }
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      console.log(err);
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const addClass = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/api/classes",
        {
          name: data.name,
          cover: data.cover,
          description: data.description,
          // teacherUid: data.teacher,
          // liveSessionsEnabled: data.liveSessionsEnabled,
          // liveSessionsOnly: data.liveSessionsOnly,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const editClass = (classId, field, value) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();

    // update body
    let updateData = {
      classUid: classId,
    };
    updateData[field] = value;

    try {
      var res = await api.patch("/api/classes/", updateData, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const addSession = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post("/api/sessions", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const editSession = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.patch("/api/sessions", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};
export const delSession = (classId, sessionId) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    const data = {
      classUid: classId,
      sessionUid: sessionId,
    };
    try {
      var res = await api.delete("/api/sessions", {
        data,
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "DELETE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "DELETE_ERROR", err });
    }
  };
};

export const delAttachment = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.delete("/api/sessions/deleteAttachment", {
        data,
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "DELETE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "DELETE_ERROR", err });
    }
  };
};

export const approveVideo = (teacherUid, classUid, sessionUid) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.put(
        `/api/uploader/sessionVideoPublish/${classUid}/${sessionUid}?teacherUid=${teacherUid}`
      );
      dispatch({ type: "APPROVE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "APPROVE_ERROR", err });
    }
  };
};

export const publishSession = (classUid, sessionUid) => {
  return async (dispatch, getState, { api }) => {
    const state = getState();
    try {
      const data = {
        classUid,
        sessionUid,
      };
      const res = await api.post(`/api/sessions/publish`, data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "PUBLISH_SUCCESS", res });
    } catch (error) {
      dispatch({ type: "PUBLISH_ERROR", error });
    }
  };
};
export const unpublishSession = (classUid, sessionUid) => {
  return async (dispatch, getState, { api }) => {
    const state = getState();
    try {
      const data = {
        classUid,
        sessionUid,
      };
      const res = await api.post(`/api/sessions/unpublish`, data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "PUBLISH_SUCCESS", res });
    } catch (error) {
      dispatch({ type: "PUBLISH_ERROR", error });
    }
  };
};

export const editClassCover = (data, classId) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      //TODO: delete old image files before editing
      var formData = new FormData();
      formData.append("image", data.file);
      var res = await api.post("/api/classes/uploadCover", formData, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200 && res.data?.data) {
        await api.patch(
          "/api/classes/",
          {
            classUid: classId,
            cover: res.data.data[0].uid,
          },
          {
            headers: {
              Authorization: "Bearer " + state.firebase.profile.token.token,
            },
          }
        );
      }
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      console.log(err);
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const editSessionCover = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      //TODO: delete old image files before editing
      var formData = new FormData();
      formData.append("image", data.file);
      var res = await api.post("/api/sessions/uploadCover", formData, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200 && res.data?.data) {
        await api.patch(
          "/api/sessions",
          {
            classUid: data.classUid,
            sessionUid: data.sessionUid,
            coverUid: res.data.data[0].uid,
          },
          {
            headers: {
              Authorization: "Bearer " + state.firebase.profile.token.token,
            },
          }
        );
      }
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      console.log(err);
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const addLiveSession = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post("/api/liveSessions", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const editLiveSession = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.patch("/api/liveSessions", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const delLiveSession = (classId, sessionId) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.delete("/api/liveSessions", {
        data: {
          classId,
          sessionId,
        },
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "DELETE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "DELETE_ERROR", err });
    }
  };
};

export const publishLiveSession = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post("/api/liveSessions/publish", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "PUBLISH_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "PUBLISH_ERROR", err });
    }
  };
};

export const delLiveAttachment = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.delete("/api/liveSessions/deleteAttachment", {
        data,
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "DELETE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "DELETE_ERROR", err });
    }
  };
};

export const addClassCardCategory = (data) => {
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState();

      var formData = new FormData();
      formData.append("image", data.file);
      var res = await api.post(
        "/api/classCard/categories/uploadCover",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );

      if (res.status === 200) {
        const final_res = await api.post(
          "/api/classCard/categories",
          {
            coverUid: res.data?.data[0].uid,
            name: data.name,
            description: data.description,
            teacherUid: data.teacherUid,
          },
          {
            headers: {
              Authorization: "Bearer " + state.firebase.profile.token.token,
            },
          }
        );

        if (!final_res.data.success) {
          dispatch({ type: "ADD_ERROR", err: "There was an error!" });
        }
      }
      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const createClassCard = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var action = await api.post(
        "/api/classCard",
        {
          amount: data.amount,
          isAvailableToPurchase: data.isAvailableToPurchase,
          name: data.name,
          description: data.description,
          teacherUid: data.teacherUid,
          type: data.type,
          validFrom: data.validFrom,
          validTill: data.validTill,
          gradientTopLeft: data.gradientTopLeft ?? "0xFF3366FF",
          gradientBottomRight: data.gradientBottomRight ?? "0xFF00CCFF",
          checkValid: data.checkValid ?? false,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "ADD_SUCCESS", res: action });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const editClassCard = (classCardUid, key, value) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      // var formData = new FormData();
      // formData.append("image", data.imageFile);
      // var res = await api.post("/api/classCard/uploadCover", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // });
      // if (!res.data.data.url) throw new Error("Card cover upload failed");

      let data = {
        classCardUid: classCardUid,
      };
      data[key] = value;

      var res = await api.patch("/api/classCard", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "EDIT_SUCCESS", res: res });
    } catch (err) {
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};
