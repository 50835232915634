import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCBZ03zdEIqXLHL67Ot3zTOFHXBma8mtvY",
  authDomain: "geo-kuppiya-ca742.firebaseapp.com",
  projectId: "geo-kuppiya-ca742",
  storageBucket: "geo-kuppiya-ca742.appspot.com",
  messagingSenderId: "733921307178",
  appId: "1:733921307178:web:5b6a99f8384e267331f153",
  measurementId: "G-ZN3SKVB6WM",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({});
// firebase.firestore().settings({
//   host: 'localhost:8008',
//   ssl: false
// });
firebase.functions();
export default firebase;
