import {
  Card,
  Col,
  PageHeader,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import Layout from "antd/lib/layout/layout";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { LoadingOutlined } from "@ant-design/icons";

import Avatar from "antd/lib/avatar/avatar";
import ImageEditModal from "../../common/CoverImageEdit";
import {
  editCategory,
  editCategoryCover,
} from "../../../store/actions/onlineClass";

const { Paragraph, Text } = Typography;

class EditCategory extends Component {
  state = {
    loading: false,
  };

  openImageEditModal = () => {
    this.setState({
      ImageEditVisible: true,
    });
  };
  closeImageEditModal = () => {
    this.setState({
      ImageEditVisible: false,
    });
  };

  updateImage = async (newImageFile) => {
    console.log(newImageFile);
    this.setState({
      loading: true,
    });

    await this.props.editCategoryCover({ file: newImageFile });

    this.setState({
      loading: false,
    });
    this.closeImageEditModal();
  };

  render() {
    const { category, classId, categoryId } = this.props;
    if (isLoaded(category, classId, categoryId)) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit Category"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                  spinning
                />
              ) : null
            }
          ></PageHeader>
          <ImageEditModal
            visible={this.state.ImageEditVisible}
            onCreate={this.updateImage}
            loading={this.state.loading}
            onCancel={this.closeImageEditModal}
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text
                      editable={{
                        onChange: (value) =>
                          this.props.editCategory("name", value),
                      }}
                    >
                      {category?.name}
                    </Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <div onClick={this.openImageEditModal}>
                    <Avatar shape="square" src={category?.coverUrl ?? ""} />
                  </div>
                }
              >
                <Row>
                  <Col span={8}></Col>
                  {/* <Col span={8}>
                    <div>
                      Live Sessions Enabled :{" "}
                      <Switch
                        defaultChecked={selected.liveSessionsEnabled}
                        onChange={(checked) =>
                          this.props.editClass("liveSessionsEnabled", checked)
                        }
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      Live Sessions Only :{" "}
                      <Switch
                        defaultChecked={selected.liveSessionsOnly}
                        onChange={(checked) =>
                          this.props.editClass("liveSessionsOnly", checked)
                        }
                      />
                    </div>
                  </Col> */}
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Space direction="vertical">
                    <div>
                      Description :{" "}
                      <Paragraph
                        editable={{
                          onChange: (value) =>
                            this.props.editCategory("description", value),
                        }}
                      >
                        {category?.description}
                      </Paragraph>
                    </div>
                  </Space>
                </Row>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit Category"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Category is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    classId: routeProps.match.params.classId,
    categoryId: routeProps.match.params.id,
    category: state.firestore.ordered.category
      ? state.firestore.ordered.category[0]
      : null,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  const classId = routeProps.match.params.classId;
  const categoryId = routeProps.match.params.id;

  return {
    editCategoryCover: (data) =>
      dispatch(editCategoryCover(data, classId, categoryId)),
    editCategory: (field, value) =>
      dispatch(editCategory(classId, categoryId, field, value)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => [
    {
      collection: "classes",
      doc: routeProps.match.params.classId,
      subcollections: [
        {
          collection: "categories",
          doc: routeProps.match.params.id,
        },
      ],
      storeAs: "category",
    },
  ])
)(EditCategory);
